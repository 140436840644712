<template>
    <ODataLookup 
        :data-object="dsChecklistLookup"
        :whereClause="getWhere()"
        reloadOnWhereClauseChange
    >
        <template #target="{ target }">
            <component v-if="is && !readOnly" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <component v-if="is && readOnly" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue" readonly></component>

            <input v-else-if="textInput && !readOnly" :ref="target" v-bind="$attrs" :value="textInputValue">
            <input v-else-if="textInput && readOnly" :ref="target" v-bind="$attrs" :value="textInputValue" readonly> 

            <span v-else :ref="target" style="cursor:pointer;">
                <slot :target="target" />
            </span>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="200"></OColumn>
        <OColumn field="Title" width="300"></OColumn>
        <OColumn field="Revision" width="80"></OColumn>
        <OColumn field="RevisionDate" width="140"></OColumn>
        <OColumn field="OrgUnit" width="200"></OColumn>
        <OColumn field="Discipline" width="150"></OColumn>
    </ODataLookup>
</template>

<script setup>
import { defineProps } from 'vue';
import { ODataLookup } from 'o365-datalookup';

const props = defineProps({
    is: String,
    IdPath: String,
    OrgUnitID: Number,
    textInputValue: String,
    textInput: Boolean,
    readOnly: {
        type: Boolean,
        required: false,
        default: false
    }
});

const dsChecklistLookup = props.OrgUnitID ? $getDataObjectById("dsCheck_ChecklistDescendantsLookup") : $getDataObjectById("dsCheck_ChecklistLookup");
const getWhere = () => {
    const clauses = [];

    if (props.OrgUnitID) {
        clauses.push(`AccessOrgUnit_ID = ${props.OrgUnitID}`);
    } else if (props.IdPath) {
        clauses.push(`'${props.IdPath}' LIKE IdPath + '%'`);
    }

    return clauses.join(' AND ');
};
</script>